import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import styled from "styled-components"
import SEO from "../components/seo"
import { PageProps } from "gatsby"
import useMedia from "../components/customHooks/useMedia"

const IndexPage: React.FC<PageProps> = ({ data }) => {
  // const image = data.images.edges.find(n => {
  //   return n.node.relativePath.includes("report.jpg")
  // })
  // const domain = "funiochiru.juggling-pintcle.com"
  const isMobile = useMedia("(max-width: 767px)")
  return (
    <Layout>
      <SEO
        lang="ja"
        title="フニオチル - Report"
        description="『フニオチル』 - 京都から「無観客」公演の試み"
        picUrl={""}
      />
      <Article>
        <TitleMain>『フニオチル』 - 京都から「無観客」公演の試み</TitleMain>

        <Section>
          <p>
            Juggling Unit ピントクル企画の公演「フニオチル」が2021年2月13、14日に行われた。
            ピントクルは現在、代表中西みみず（以下敬称略）が中心となってさまざまな企画を行っているが、今回は、ジャグラー3組がそれぞれの演目を10-15分ほど披露し、その様子をオンライン（Youtube）で無料配信した。
            感染症拡大で「やむを得ず」無観客配信を行う事例が多い中、あえて「積極的に」無観客配信を行う、とする。
          </p>
          <p>
            「身体と道具をつなぐもの」としてのジャグリングを見せることがテーマで、無観客の本番というものが「孤独に『もの』と向き合うことの意味を示しているように思われた」とするのがその理由。
          </p>
          <p>
            会場のSocial Kicthen は、もともと西陣織の工場であったところをDIYで改装した（カフェで料理を提供していた方談）という趣のある建物である。
            1階はカフェ、2階は貸スペース。
            出演者は、大橋昂汰、染谷樹、KOMEIの3組。(大橋は、子供たちと共同の「大橋家」名義で参加）
            それぞれ、大会入賞、大きな舞台への出演経験などを持つ、確かな技術を持ったジャグラーだ。
          </p>
          <p>
            以下、各演目の概要と演者について、筆者の視点から紹介する。
          </p>
          <p>
            演技の内容はすべてアーカイブで視聴が可能。
          </p>
        </Section>

        <Section>
          <PartTitle>
            大橋家
          </PartTitle>
          <p>
            予め撮ってきたビデオを壁に映し、大橋が映像の前でジャグリングをする。ビデオに映るのは、地元・長崎で大橋がジャグリングを教える子供達。ジャグリング以外にも出発日の映像、練習風景の写真など、ドキュメンタリー風の味付けもある。大橋のジャグリングは世界標準から見ても非常に高い技術の裏打ちがある。軌道を鮮やかに変化させ、ボールと身体の位置関係で空間に模様を描くようなスタイルだ。美的な観点からも国内外のジャグラーから定評がある。映像に映る後ろの子供達は、大橋のジャグリングをやや簡素化した動きを同時に行う（大橋が映像にシンクロして動く）。途中、映像の中の子供達とジャグリング勝負をする場面などもあり、直接演技スペースに集うことができない中で、擬似的に実際にやりとりをしているように見える工夫がされていた。
            大橋は、ジャグリングが盛んな都市圏とは離れたところで長年ジャグリングを独学で追求してきている人物。この子供達は、特にジャグリングをやりたい人を意識的に集めたのではなく、近所の子供達が、児童館や公園でジャグリングをする大橋に勝手に習っているのだそうである。サークル活動や教育的なプログラムのもとでジャグリングを習得している集団ではなく、あくまでジャグリングを通して自然に出来上がったコミュニティの姿が、そのジャグリングを通して見えてくる。
          </p>
        </Section>

        <Section>
          <PartTitle>
            染谷樹
          </PartTitle>
          <p>
            染谷は、シガーボックスなど、「ジャグリング向けにデザインされた道具」を扱う技術も高い。しかし最近は、靴、パソコン、ハンガー、額縁といった、日常のものを扱う演技も多く発表している。今回は靴を用いた演目。自分が履いている靴と同一の靴を、足の動きと同時に手でも操る。どれが演者の「履いている」靴で、どれが「手で操られている」靴なのかが曖昧になり、視覚的な錯覚を与える。見えないもう一人の人間がいるかのような演出もある。配信は、撮影方法を変えて同じ内容で二度行われた。固定カメラでの撮影と、カメラマンによる手持ちカメラ撮影。映し方によって受ける印象が変化することを狙う。
            日本に限らず世界のジャグリングシーンでは、いわゆる「ジャグリング」の技術を持った人が、その技術を援用して別のモノで新しい表現を模索する、ということも盛んに行われている。染谷はここ最近特に、そのようなアプローチからの動き方を意欲的に披露している。
            </p>
        </Section>

        <Section>
          <PartTitle>
            KOMEI
          </PartTitle>
          <p>
            KOMEIは、日本のジャグリング黎明期（2000年代初頭）からシーンに大きな影響を与えてきた人物。既存のものとは違ったジャグリングを、さまざまな道具で、独自の手法で追求し続けている。KOMEIは、そのジャグリング人生の早期から、VHSなどの媒体を通して世界のジャグラーから影響を受けてきた。そしてKOMEI自身も十数年間、今も現役で、映像や国内外イベントへの出演を通して世界のジャグラーに影響を与えている。今回の演目では、3種類のボールを使った演技を披露。これらボールはすべて、殻の材質、重量、中身などが違う。ボールの性質によって、行われるジャグリングの系統も違う。ボールの特徴によって、可能なこと、あるいはやりやすいことが少しずつ異なるためである。シンプルに3つのボールだけを使って見せるジャグリングが約20分。しかし、随所でさらなる新しいボールジャグリングのジャンルの発展を予感させる動きが見られた。
          </p>
        </Section>
        <Section>

          <p>※※※</p>

          <p>
            公演時間は3時間。演目と演目の合間では、出演者（とスタッフ）が、棚に置いてある雑貨やジャグリング道具を使って、「新しい」ジャグリングのアイデアを出す「即興モノボケジャグリング大会」も行われた。3組の演者はそれぞれジャグリングのスタイルが大きく異なるのだが、共通して言えるのは、「ジャグリング的なモノの使い方」の発見能力がとても高い、ということであった。
            つまり、その場に置いてあるランダムな雑貨を即興で組み合わせて「技術を必要とする芸」を生み出すのだが、質の差はあれど、その発案のスピードがとても速い。
            実際にやってみると、これがとても難しいのである（筆者も体験済み、一発ギャグしか思いつかなかった）。
          </p>

          <p>
            「あるモノに固有の新しい遊び方を想像する能力」は、まさしく、新しいジャグリングを生み出す上で非常に重要な能力でもある。
          </p>

          <p>
            ジャグリングの世界では、既存のデザインされた道具で、既存の体系を学ぶことも広く楽しまれている。
            一方で、ジャンルとしてのジャグリングを、使用するモノ、技術の体系といった観点から拡張していくことも行われており、今回紹介された3人は、その一翼を積極的に担うジャグラーたちである。
            </p>

          <p>
            今回の無観客配信は、「公演」という名前はつきながらも、どちらかと言うと「ジャグラーがどのようなプロセスでものの扱い方を研究するのか」ということの一例を垣間見るような企画になっていたのではないかと思う。
            そういった意味で「（日本の在野の）ジャグリング」にある雰囲気を掴むための「入門映像」として見ることができる。
            </p>

          <p>
            大道芸、サーカスといった一般に広く認知された分野でジャグリングを見るだけでは把握することのできない、日本のジャグリングカルチャーにおけるいくつかの「系譜」の一端が、この公演には収められいてる。
          </p>
        </Section>

      </Article>
    </Layout>
  )
}

export default IndexPage

const Article = styled.article`
  padding: 2rem 1rem 8rem;
  color: #212121;
`

const Section = styled.section`
  margin-bottom: 2rem;
`

const TitleMain = styled.h1`
  margin-bottom: 2rem;
  font-size: 1.825rem;
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: 2.4px;
  /* タブレットも含む */
  @media screen and (max-width: 768px) {
   font-size: 1.25rem;
  }
`

const PartTitle = styled.h3`
  font-size: 1.125rem;
  line-height: 1.75;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #767676;
`